import { z } from "zod";

// Also define this in double/turbo.json so turborepo doesn't re-use cache when env vars have
// changed.
const reactAppEnvVarSchema = z.object({
  FLAGSMITH_ENV_ID: z.string(),
  GA_TRACKING_ID: z.string(),
  REACT_APP_CLERK_PUBLISHABLE_KEY: z.string(),
  REACT_APP_ENV: z.string(),
  REACT_APP_SUPABASE_ANON_KEY: z.string(),
  REACT_APP_SUPABASE_URL: z.string().url(),
  REACT_APP_TRPC_URL: z.string().url(),
  VERCEL_GIT_COMMIT_SHA: z.string().optional(),
});

function getEnv(envVars: { [name: string]: string }): Env {
  try {
    return reactAppEnvVarSchema.parse(envVars);
  } catch (error) {
    console.error("Missing env vars. Do you have Doppler set up?");
    throw error;
  }
}

export type Env = z.infer<typeof reactAppEnvVarSchema>;

declare global {
  interface Window {
    ENV: Env;
  }
}

const isServerSide = typeof document === "undefined";
// @ts-expect-error: playwrightCtEnv is defined by Vite during Playwright component tests.
const isPlaywrightComponentTestEnv = typeof playwrightCtEnv !== "undefined";

// eslint-disable-next-line import/no-mutable-exports
let env: Env;

if (isPlaywrightComponentTestEnv) {
  // If it's called during a Playwright component test, Playwright will pass environment variables
  // to the frontend environment as playwrightCtEnv, via Vite.

  /* eslint-disable @typescript-eslint/no-unsafe-argument */
  // @ts-expect-error: playwrightCtEnv is defined by Vite during Playwright component tests.
  env = getEnv(playwrightCtEnv);
  /* eslint-enable @typescript-eslint/no-unsafe-argument */
} else if (isServerSide) {
  // If it's called on the server side, we need to read the environment variables from process.env.
  // @ts-expect-error: process.env will fit the Env type.
  env = getEnv(process.env);
} else {
  // If it's called on the client side, environment variables are passed from the backend via the
  // root.tsx loader into window.ENV.
  env = window.ENV;
}

export { env };
