import { env } from "./utils/frontend-env";

export const enableIntercom = env.REACT_APP_ENV === "prd";
export const enableHighlight = env.REACT_APP_ENV === "prd";
export const enableSentry = env.REACT_APP_ENV === "prd";
export const enableMixpanel = env.REACT_APP_ENV === "prd";
export const SENTRY_DSN =
  "https://526608bb4391426e8bba8001680c4b40@o4504138773889024.ingest.sentry.io/4504138775265280";

// Helper to avoid @typescript-eslint/no-unnecessary-condition downstream when using a flag that is meant to be just hard coded boolean.
// const dynamicTrue = env.REACT_APP_ENV !== ""; // Always evaluates to true.
// const dynamicFalse = !dynamicTrue; // Always evaluates to false.
